<template>
  <main class="quoteDetPg">
    <div class="secHead pb-3 mb-3">
    
      <h4 class="mb-0 primaryColor fw-bold">Quote Details:</h4>
    </div>
    <div class="secMain">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="form-group">
            <label>Quote ID:</label>
            <p class="mb-0">{{ quoteDetail.id }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="form-group">
            <label>Claim ID:</label>
            <p class="mb-0">{{ quoteDetail.partner_info.job_number }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="form-group">
            <label>Consumer Name:</label>
            <p class="mb-0">{{ quoteDetail.consumer.full_name }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="form-group">
            <label>Phone Number:</label>
            <p class="mb-0">
              <a :href="'tel:' + quoteDetail.consumer.phone"
                >{{ quoteDetail.consumer.phone }}
              </a>
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="form-group">
            <label>Services Type:</label>
            <p class="mb-0">{{ quoteDetail.job.title }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="form-group">
            <label>Source Type:</label>
            <p class="mb-0">{{ quoteDetail.job_source.title }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="form-group">
            <label>Appointment ID:</label>
            <p class="mb-0">{{ quoteDetail.job_source.id }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="form-group">
            <label>Quote Status:</label>
            <p class="mb-0">
              <a style="color: #ff5b01;">
                {{ quoteDetail.status.technician.text }}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="googleApi">
          <p>{{ apptDetail.address }}</p>
          <GMapMap
            :center="center"
            :zoom="16"
            map-type-id="terrain"
            style="width: 100%"
            class="locationMap"
            :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            }"
          >
            <GMapMarker
              v-for="(m, index) in markers"
              :key="index"
              :position="m.position"
              :clickable="false"
              :draggable="false"
            />
          </GMapMap>

          <div class="clickSec d-md-flex justify-content-between">
            <p class="mb-0"><u class="underline clickBtn me-1" @click="openGoogleMaps()">Click here </u> to use Google Maps Navigation</p>
            <p class="mb-0">  {{ quoteDetail.partner_info.company_name }} <img :src="quoteDetail.partner_info.company_logo" class="ms-1"></p>
          </div>
          
        </div>
      </div>
    </div>
  </main>
  <!-- <div class="container">
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="userDetails">
          <h2>Quote Details :</h2>
          <ul class="detail-list">
            <li><span>Quote ID :</span> {{ quoteDetail.id }}</li>
            <li v-if="quoteDetail.partner_info.id > 0">
              <span>Claim ID :</span>
              {{ quoteDetail.partner_info.job_number }}
            </li>
            <li>
              <span>Consumer Name :</span> {{ quoteDetail.consumer.full_name }}
            </li>
            <li>
              <span>Phone Number :</span>
              <a :href="'tel:' + quoteDetail.consumer.phone"
                >{{ quoteDetail.consumer.phone }}
              </a>
            </li>
            <li v-if="!isEmpty(quoteDetail.schedule_datetime)">
              <span>Visit Schedule :</span>
              {{ convertTimsStampToDate(quoteDetail.schedule_datetime) }}
            </li>
            <li><span>Services Type :</span> {{ quoteDetail.job.title }}</li>
            <li>
              <span>Source Type :</span> {{ quoteDetail.job_source.title }}
            </li>
            <li>
              <span>Appointment ID :</span> {{ quoteDetail.job_source.id }}
            </li>
            <li>
              <span>Quote Status :</span>
              <a class="text-orange">
                {{ quoteDetail.status.technician.text }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <div class="location-map">
          <h2>{{ apptDetail.address }}</h2>
          <p class="googleLink googleLinkMob">
            <u class="underline" @click="openGoogleMaps()">Click here </u> to
            use Google Maps Navigation
          </p>
          <GMapMap
            :center="center"
            :zoom="16"
            map-type-id="terrain"
            style="width: 100%"
            class="locationMap"
            :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            }"
          >
            <GMapMarker
              v-for="(m, index) in markers"
              :key="index"
              :position="m.position"
              :clickable="false"
              :draggable="false"
            />
          </GMapMap>
        </div>
        <p class="googleLink">
          <u class="underline" @click="openGoogleMaps()">Click here </u> to use
          Google Maps Navigation
        </p>
      </div>
    </div>
  </div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {},

  data() {
    return {
      originlat: 0,
      originlng: 0,
      actionButtons: [],
      err: null,
      center: {
        lat: 0,
        lng: 0,
      },
      markers: [
        {
          position: {
            lat: 0,
            lng: 0,
          },
        }, // Along list of clusters
      ],
    };
  },
  computed: {
    ...mapGetters(["quoteDetail", "apptDetail"]),
  },

  watch: {
    apptDetail: function () {
      this.markers[0].position.lat = this.apptDetail.lat;
      this.markers[0].position.lng = this.apptDetail.lng;

      this.center.lat = this.apptDetail.lat;
      this.center.lng = this.apptDetail.lng;
    },
  },

  mounted() {
    if (this.apptDetail == []) {
      this.markers[0].position.lat = this.apptDetail.lat;
      this.markers[0].position.lng = this.apptDetail.lng;

      this.center.lat = this.apptDetail.lat;
      this.center.lng = this.apptDetail.lng;
    } else {
      if (this.quoteDetail != null) {
        this.getAppointmentDetailsAPICall({
          id: this.quoteDetail.job_source.id,
        });
      }
    }
  },

  methods: {
    ...mapActions(["getAppointmentDetailsAPICall"]),
    convertTimsStampToDate(val) {
      return moment.unix(val).format("MM/DD/YYYY - hh:mm A");
    },

    convertNotesTimsStampToDate(val) {
      return moment.unix(val).format("hh:mm a MM/DD/YYYY");
    },

    isEmpty(value) {
      return (
        value == null ||
        (value + "").trim().length === 0 ||
        (value.constructor === Object && Object.keys(value).length === 0)
      );
    },
    openGoogleMaps() {
      const desLat = this.apptDetail.lat ?? 0;
      const desLng = this.apptDetail.lng ?? 0;
      const googleMapsUrl = `https://maps.google.com/?q=${desLat},${desLng}`;

      // Check if the Google Maps app is installed
      if (navigator.platform.indexOf("Win") > -1) {
        // Windows platform, open in a new window
        window.open(googleMapsUrl, "_blank");
      } else {
        // Non-Windows platform, try to open in the maps app
        window.location.href = googleMapsUrl;
        //  window.location.href =`geo:0,0?q=${encodeURIComponent(destination)}`;
      }
    },
  },
};
</script>

<style>
.secHead {
  border-bottom: 1px solid #ccc;
}
.secMain .form-group {
  margin-bottom: 1rem;
}
.secMain label {
  display: block;
  font-size: 18px;
}
.secMain p {
  font-weight: 400;
  color: #092a8c;
  font-size: 18px;
}
.secMain p a {
  text-decoration: none;
  color: #092e87;
  text-decoration:underline;
  cursor: pointer;
}
.googleApi {
  margin: 2rem 0 1rem 0;
}
.googleApi p {
  color: #333333;
  font-size: 18px;
}
.googleApi .locationMap {
  height: 400px;
  margin-bottom: 1rem;
}

/* .userDetails h2 {
  font-size: 22px;
  color: #092e87;
  font-weight: normal;
}

.detail-list {
  list-style: none;
  padding: 0;
}

.detail-list li {
  color: #092e87;
  font-size: 18px;
  line-height: 40px;
}

.detail-list li span {
  color: #000;
}

.detail-list li a {
  color: #fe5000;
  text-decoration: none;
}

.location-map {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 5px #999;
}

.location-map h2 {
  color: #092e87;
  font-size: 18px;
  padding: 17px 17px;
  background: #d9d9d9;
  font-weight: normal;
}

.googleLink {
  font-size: 17px;
  color: #000;
  margin-top: 30px;
} */

.googleLink a {
  color: #fe5000;
}

/* .notesBox {
  padding: 15px;
  background: #f8f8f8;
  min-height: 150px;
  overflow: auto;
  border: 1px solid #747171cf;
  border-radius: 10px;
  margin-bottom: 30px;
}

.main-button {
  background: #fe5000;
  border-radius: 8px;
  box-shadow: 0 4px 5px #999;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px;
}

.main-button:hover {
  background-color: #092e87;
  color: #fff;
}

.locationMap {
  height: 255px;
} */
.clickSec p {
    font-size: 15px;
  }
.clickSec p:nth-child(2) img {
    width: 60px;
  }

@media screen and (max-width: 1000px) {
  .googleApi .locationMap {
    height: 235px;
    display: block;
  }
}

.underline {
  color: #092e87;
  font-weight: 600;
  cursor: pointer;
}

.text-orange {
  color: #fe5000;
}
.clickBtn{
  /* background: #FFF0E9; */
  background: transparent !important;
  padding: 0.5rem;
  border-radius: 5px;
  color: #092e87 !important;
}

.googleLink.googleLinkMob {
  display: none;
}

@media screen and (max-width: 767px) {
  .main-button:hover {
    background-color: #fe5000 !important;
    color: #fff;
  }

  .locationMap {
    display: none;
  }

  .googleLink {
    display: none;
  }

  .googleLink.googleLinkMob {
    display: block;
    padding: 0 17px;
    margin-top: 10px;
  }

  .notesBox {
    margin-top: 20px;
  }

  .location-map {
    margin-bottom: 20px;
  }

  .detail-list li {
    line-height: 32px;
  }
}
@media screen and (max-width: 575px) {
  .secMain .form-group {
    display: flex;
    margin-bottom: 0.2rem;
  }
  .secMain label {
    font-size: 16px;
    min-width: 150px;
    font-weight: 600;
  }
  .secMain p {
    font-size: 16px;
  }
  .clickSec p:nth-child(2) img {
    margin-right: 0.5rem;
  }

  .clickSec p:nth-child(2) {
      text-align: left;
      margin-top: 2rem;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
  }
}
</style>
